import React from "react"
import Layout from "../../components/layout"
import CLogin from "../../components/admin/login/login"
import { Helmet } from "react-helmet"

const Login = () => {
  return (
    <Layout type="hr" noHeaderOptions>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Atolo Login</title>
      </Helmet>
      <div className="h-full w-full flex-1 md:w-11/12 lg:w-4/5 xl:w-3/5 md:pt-8 md:pb-20">
        <CLogin />
      </div>
    </Layout>
  )
}

export default Login
