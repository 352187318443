import React, { useState } from "react"
import Modal from "../../layout/modal"
import Button from "../../base/buttons/regular"
import SButton from "../../base/buttons/secondary"
import { useWindowSize } from "../../../utils/hooks/useWindowSize"
import useLogin from "../../../utils/hooks/useLogin"
import usePasswordResetRequest from "../../../utils/hooks/usePasswordResetRequest"
import TextInput from "../../base/forms/textInput"
import BlackOverlay from "../../layout/blackOverlay"
import Title from "../../layout/title"
import { IoMdClose } from "react-icons/io"
import GreenLine from "../../layout/greenLine"
import notifications from "../../../utils/notifications"
import { defineMessages, navigate } from "gatsby-plugin-intl"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import Label from "../../base/forms/label"

const messages = defineMessages({
  welcomeTitle: "Welcome to the Atolo Test Dashboard",
  usernameInputPlaceHolder: "Username or email",
  passwordInputPlaceHolder: "Password",
  resetPasswordButton: "Reset password",
  resetPasswordEmailLabel: "Enter your email",
  resetPasswordSubmitButton: "OK",
  forgotPasswordButton: "Forgot your password?",
  loginButton: "Login",
})

const Login = () => {
  const [navigationVisible, setNavigationVisible] = useState(true)
  const [widthOfModal, setWidthOfModal] = useState("w-3/5")
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false)
  const formatMessage = useFormatMessage()

  const currentSize = useWindowSize()
  const { loading, login: executeLogin } = useLogin()
  const { requestResetPassword } = usePasswordResetRequest()

  /* Determine if navigation arrows should be displayed based on window width */
  if (["sm", "md"].includes(currentSize) && navigationVisible) {
    setNavigationVisible(false)
    setWidthOfModal("w-full")
  } else if (!["sm", "md"].includes(currentSize) && !navigationVisible) {
    setNavigationVisible(true)
    setWidthOfModal("w-3/5")
  }

  const handleLogin = async () => {
    try {
      await executeLogin(userName, password)
      navigate("/admin")
    } catch (err) {
      notifications.error("Oops! Something went wrong.", err.message, err.details)
    }
  }

  const handleResetPassword = async () => {
    try {
      await requestResetPassword(email)
      setResetPasswordVisible(false)
      notifications.success("Password reset successful!", "You should receive an email shortly.")
    } catch (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }
  return (
    <>
      <Modal noTitle mdWidth={widthOfModal} navigation={false} mobile={!navigationVisible} lgWidth="w-3/5" next="/admin">
        <div className="text-gray-700 text-2xl text-center font-semibold">Atolo HR</div>
        <form
          onSubmit={e => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <div className="mt-5 items-center flex flex-col">
            <TextInput
              placeholder={formatMessage(messages.usernameInputPlaceHolder)}
              value={userName}
              onChange={e => setUserName(e.target.value)}
              className="py-2 w-64"
            />
            <TextInput
              type="password"
              placeholder={formatMessage(messages.passwordInputPlaceHolder)}
              className="py-2 w-64"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="flex flex-col justify-center items-center w-full pb-8 pt-4">
            <div className="w-1/2">
              <Button type="submit" className="text-center w-full" primary loading={loading}>
                {formatMessage(messages.loginButton)}
              </Button>

              <SButton onClick={() => setResetPasswordVisible(true)} className="text-center w-full">
                {formatMessage(messages.forgotPasswordButton)}
              </SButton>
            </div>
          </div>
        </form>
      </Modal>
      <div className={`${resetPasswordVisible} absolute top-0 left-0 mt-20 w-full flex flex-col items-center`}>
        <div className={`${resetPasswordVisible ? "flex flex-col" : "hidden"} bg-white shadow max-w-3xl rounded items-center z-50 `}>
          <GreenLine />
          <IoMdClose size={22} className="m-2 cursor-pointer self-end " onClick={() => setResetPasswordVisible(false)} />
          <div className="p-10 pt-4 items-center">
            <Title>{formatMessage(messages.resetPasswordButton)}</Title>
            <Label name={formatMessage(messages.resetPasswordEmailLabel)}>
              <TextInput placeholder="Email" value={email} name="email" onChange={e => setEmail(e.target.value)} />
            </Label>
            <Button primary onClick={handleResetPassword} className="self-center text-center w-full my-8">
              {formatMessage(messages.resetPasswordSubmitButton)}
            </Button>
          </div>
        </div>
        <BlackOverlay visible={resetPasswordVisible} onClose={() => setResetPasswordVisible(false)} />
      </div>
    </>
  )
}

export default Login
